import { Box, Container, Rating, Typography } from '@mui/material';
import React from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';
import TestimonialCard from './TestimonialCard';
import "swiper/css";
import CustomCarousel from '../custom/CustomCarousel/CustomCarousel';
import person1 from './../../images/testimonials/person (1).png';
import person2 from './../../images/testimonials/person (2).png';
import person3 from './../../images/testimonials/person (3).png';
import person4 from './../../images/testimonials/person (4).png';
import person5 from './../../images/testimonials/person (5).png';
const data = [
    {
        text: [`We were impressed with them the promise of 1-10 lead a week, and they overdid it and generated 78 leads in 8 weeks.`,
            `We just wanted to thank you guys for the amazing job. I still can’t believe how many clients I had access to through my LinkedIn account.`],
        image: person1
    },
    {
        text: [`B2B Linked Up is incredible to work with and within two months of getting started, we had generated over 45 new leads for our business.`,
            `B2BLinkedUp is easily the best investment I’ve made in marketing my business since launching.`],
        image: person2,
    },
    {
        text: [`B2BLinkedUp has a system of connecting with key decision-makers through LinkedIn and email that simply works. I can't overstate how effective they are at what they do.`,
            `I get qualified leads week in week out, on (almost) autopilot. Typically, I have 3-7 sales calls a week generated by B2B Linked Up.`],
        image: person3,
    },
    {
        text: [`I trust that absolutely everyone in Sales have to use this system because it works.  It’s effective. It continues to bring me ever-increasing amount of qualified leads and I’m making money at it.`,
            `I have been able to generate as many as 5-7 qualified leads per week.`],
        image: person4
    },
    {
        text: [`I can say being in business development for a lot of years this is the best system I have ever seen. Every business development rep should have it. The service is excellent value for money.`,
            `Typically, I get 3-7 sales qualified leads a week generated by B2B Linked Up’s work alone.`],
        image: person5
    }

]
const Testimonials = () => {
    const slides = data.map(({ text, image }, i) => (
        <TestimonialCard key={i}>
            <img src={image} alt="" />
            {
                text.map((item, i) => <Typography key={i * 2} className='text'>{item}</Typography>)
            }
            <Rating value={5} readOnly />
        </TestimonialCard>
    ))
    return (
        <Container id="testimonials">
            <SectionTitle><span>Customer</span> Testimonials</SectionTitle>
            <Box component={"div"} sx={{ py: 8 }}>
                <CustomCarousel slides={slides} />
            </Box>
        </Container>
    );
};

export default Testimonials;