import { Box, Card, CardActions, CardContent, Divider, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import CustomModal from '../custom/CustomModal/CustomModal';
import PricingForm from './PricingForm';
const PricingCard = ({ single }) => {
    const [closeModal, setCloseModal] = useState({ status: false })
    const { packageName, price, total, month } = single;
    return (
        <Card variant='outlined' sx={{
            p: 2,
            height: "100%",
            bgcolor: "#f0f0f0",
            borderColor: "#0196c4",
            transition: ".3s",
            '&:hover': {
                bgcolor: "#0196c4",
                transform: "scale(1.02)",
                '& button': {
                    bgcolor: "#fff",
                    color: "#0196c4"
                },
                '& .title': {
                    color: "#fff",
                }
            }
        }}>
            <CardContent>
                <Typography sx={{ fontSize: 18, fontWeight: 600, fontFamily: "Montserrat" }} gutterBottom>
                    {packageName}
                </Typography>
                <Divider />
                <Typography className='title' sx={{ fontSize: 30, mt: 2, fontWeight: 600, fontFamily: "Montserrat", color: "#0196c4" }} variant="h5">
                    ${price} <Box component={"span"} sx={{ fontSize: 15 }}>/ month</Box>
                </Typography>
                <Box sx={{ '& ul': { pl: 2, }, my: 3 }}>
                    {
                        packageName === "Pro Package" ?
                            <ul>
                                <li>4000 prospect per month</li>
                                <li>Linkedin + Email Outreach</li>
                                <li>InMail 1000</li>
                            </ul> :
                            <ul>
                                <li>1500 prospect per month</li>
                                <li>Only Linkedin Outreach</li>
                                <li>InMail 300</li>
                            </ul>
                    }
                </Box>
                <Typography className='title' variant="h4" sx={{ fontSize: 20, color: "#0196c4", fontWeight: 600, fontFamily: "Montserrat", textAlign: "center" }}>
                    ${total} <Box component={"span"} sx={{ fontSize: 15 }}>in total</Box>
                </Typography>
            </CardContent>
            <CardActions sx={{ justifyContent: "center" }}>
                <CustomModal closeModal={closeModal} title='Buy Now' buttonStyle={{ padding: "7px 25px !important", fontSize: "16px !important" }}>
                    <PricingForm packageName={packageName} month={month} setCloseModal={setCloseModal} />
                </CustomModal>
            </CardActions>
        </Card>
    );
};

export default PricingCard;