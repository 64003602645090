import styled from "@emotion/styled";
import { Typography } from "@mui/material";


const SectionTitle = styled(Typography)({
    fontSize: 40,
    fontFamily: "'Montserrat', sans-serif",
    color: "#222",
    marginTop: 60,
    marginBottom: 50,
    paddingBottom: 5,
    textAlign: 'center',
    fontWeight: 600,
    position: 'relative',
    '& span': {
        color: '#0196c4'
    },
    '&::before': {
        content: '""',
        position: 'absolute',
        bottom: 0,
        height: 5,
        left: '50%',
        transform: 'translateX(-50%)',
        width: 150,
        background: "linear-gradient(230deg, #0196c4 20%, #082351 66%)"
    }
})
export default SectionTitle