import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import { useState } from 'react';
import { useEffect } from 'react';
import DashboardTable from '../components/Table/Table';
import { getContact, getNewsLetter, getPopup, getPriceRequest, getPricingRequest } from '../service/formService';

const Dashboard = () => {
    const [priceRequest, setPriceRequest] = useState([]);
    const [popup, setPopup] = useState([]);
    const [contact, setContact] = useState([]);
    const [newsLetter, setNewsLetter] = useState([]);
    const [pricingData, setPricingData] = useState([])
    useEffect(() => {
        getPriceRequest().then(res => {
            setPriceRequest(res.data)
        })
        getPopup().then(res => {
            setPopup(res.data)
        })
        getContact().then(res => {
            setContact(res.data)
        })
        getNewsLetter().then(res => {
            setNewsLetter(res.data)
        })
        getPricingRequest().then(res => {
            setPricingData(res.data)
        })
    }, [])

    return (
        <Box py={4}>
            <Container>
                <Typography variant='h4' sx={{ textAlign: "center", my: 4 }}>Pricing Request</Typography>
                <DashboardTable rows={priceRequest} />
                <Typography variant='h4' sx={{ textAlign: "center", my: 4 }}>Popup</Typography>
                <DashboardTable rows={popup} />
                <Typography variant='h4' sx={{ textAlign: "center", my: 4 }}>Pricing Data</Typography>
                <DashboardTable rows={pricingData} />
                <Typography variant='h4' sx={{ textAlign: "center", my: 4 }}>Contact</Typography>
                <DashboardTable rows={contact} />
                <Typography variant='h4' sx={{ textAlign: "center", my: 4 }}>NewsLetter</Typography>
                <DashboardTable rows={newsLetter} />
            </Container>
        </Box>
    );
};

export default Dashboard;