import axios from "axios";
// const API_BASE = 'http://localhost:5000/api/'
const API_BASE = process.env.REACT_APP_API_BASE
export const addPriceRequest = (payload) => {
    return axios.post(API_BASE + 'pricing-request', payload)
}

export const addPopup = (payload) => {
    return axios.post(API_BASE + 'popup', payload)
}

export const addContact = (payload) => {
    return axios.post(API_BASE + 'contact', payload)
}

export const addNewsLetter = (payload) => {
    return axios.post(API_BASE + 'newsletter', payload)
}
export const addPricingRequest = (payload) => {
    return axios.post(API_BASE + 'pricing-data', payload)
}



export const getPriceRequest = () => {
    return axios.get(API_BASE + 'pricing-request')
}

export const getPopup = () => {
    return axios.get(API_BASE + 'popup')
}

export const getContact = () => {
    return axios.get(API_BASE + 'contact')
}

export const getNewsLetter = () => {
    return axios.get(API_BASE + 'newsletter')
}
export const getPricingRequest = () => {
    return axios.get(API_BASE + 'pricing-data')
}