import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';

const WhatToExpect = () => {
    return (
        <Container>
            <SectionTitle><span>What to</span> Expect</SectionTitle>
            <Grid container spacing={4}>
                <Grid item md={6} xs={12}>
                    <Box>
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/bCK_F1ZYSNo" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Box>
                    <Typography variant='h5' sx={{ textAlign: 'center' }}>Our Advantages </Typography>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Box>
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/8otA9WycfHg" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Box>
                    <Typography variant='h5' sx={{ textAlign: 'center' }}>Conversion &amp; Results
                    </Typography>
                </Grid>
            </Grid>
        </Container>
    );
};

export default WhatToExpect;