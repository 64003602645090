import { Container, Grid } from '@mui/material';
import React from 'react';
import { FAQData } from '../../data/FAQdata';
import CustomAccordion from '../custom/CustomAccordion/CustomAccordion';
import SectionTitle from '../SectionTitle/SectionTitle';

const FAQ = () => {
    return (
        <Container id="faq">
            <SectionTitle> <span>Frequently</span> Asked Questions</SectionTitle>
            <Grid container spacing={2}>
                <Grid item md={6} xs={12}>
                    <CustomAccordion data={FAQData.slice(0, 6)} />
                </Grid>
                <Grid item md={6} xs={12}>
                    <CustomAccordion data={FAQData.slice(6, FAQData.length)} />
                </Grid>
            </Grid>
        </Container>
    );
};

export default FAQ;