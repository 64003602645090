import styled from "@emotion/styled";
import { Paper } from "@mui/material";


const StepCard = styled(Paper)({
    boxShadow: '0 0 10px rgba(0,0,0,.05)',
    minHeight: 270,
    transition: '.3s',
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    padding: 15,
    '& .icon': {
        height: 120,
        border: "3px solid #0196c4",
        borderRadius: '50%',
        padding: 20,
        '& img': {
            height: '100%',
            transition: '.3s'
        }
    },
    '& .text': {
        fontSize: 16,
        marginTop: 25,
        textAlign: 'center'
    },
    '&:hover': {
        boxShadow: '0 0 15px rgba(0,0,0,.15)',
        transform: 'translateY(-5px)',
        '& .icon img': {
            transform: 'rotateX(20deg) rotateY(20deg)'
        }
    }

})
export default StepCard