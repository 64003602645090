import { Box, Typography } from '@mui/material';
import React from 'react';
import { HashLink } from 'react-router-hash-link';
import MainButton from '../MainButton/MainButton';

const OutreachQuestionsButton = () => {
    return (
        <Box sx={{ mt: 10, display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center", '& a': { textDecoration: "none" } }}>
            <Typography variant='body1' sx={{ fontWeight: 600 }}>  Interested in our Linkedin Outreach?</Typography>
            <Typography variant='body1' sx={{ fontWeight: 600, mb: 3 }}>  Please click below to fill out our</Typography>
            <HashLink to="/outreach-questionnaire#outreach">
                <MainButton sx={{ color: "#fff" }}>LinkedIn Outreach Questionnaire</MainButton>
            </HashLink>
        </Box>
    );
};

export default OutreachQuestionsButton;