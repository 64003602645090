import styled from "@emotion/styled";
import { Paper } from "@mui/material";


const TestimonialCard = styled(Paper)({
    boxShadow: '0px 90px 160px 0px rgb(0 0 0 / 6%)',
    transition: '.3s',
    padding: 20,
    textAlign: 'center',
    borderRadius: 20,
    minHeight: 400,
    '& img': {
        borderRadius: '50%',
        height: 120,
        width: 120,
        objectFit: 'cover'
    },
    '& .text': {
        fontSize: 16,
        marginTop: 25,
        marginBottom: 15,
        textAlign: 'justify'
    },
    '&:hover': {
        boxShadow: '0 0 15px rgba(0,0,0,.15)',
    }

})
export default TestimonialCard