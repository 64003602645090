import { Container, Typography } from '@mui/material';
import React from 'react';
import "./flowchart.css";
const Flowchart = () => {
    return (
        <Container>
            <div className="flowchart">
                <h1 class="level-1 rectangle">
                    <Typography sx={{ fontSize: 30, fontWeight: 700 }}>
                        Example <br />
                        Total Extract- 6000 profile
                    </Typography>
                </h1>
                <ol class="level-2-wrapper">
                    <li>
                        <h2 class="level-2 rectangle">
                            <Typography sx={{ fontSize: 22, fontWeight: 600 }}>Active on Linkedin 3500 <br /> Linkedin Outreach Campaign</Typography>
                        </h2>
                        <ol class="level-3-wrapper">
                            <li>
                                <ol class="level-4-wrapper">
                                    <li>
                                        <div class="level-4 rectangle">
                                            <Typography variant='body1'>
                                                <span dangerouslySetInnerHTML={{
                                                    __html: `
                                                    1. Average Connection request Send (with personalized ad note) 1200-2000 <br/>
                                                    2. 500-800 connections are expected to be accepted on average <br/>
                                                    3. We create a sequence with various conditions <br/>
                                                    4. Re-engagement messages to accepted prospects (3-4 touchpoints) <br/>
                                                    
                                                    * A second message will be sent the next day (introducing you and your company) <br/>
                                                    * 7 days later, message 3 will be sent (a message with more information about your offering and a call-to-action for the prospect (e.g. schedule a call) <br/>
                                                    * One month after they receive Message 3, we will re-engage unresponsive connections with value messaging designed to demonstrate your expertise.  <br/>
                                                    
                                                    5. Send In-mail ( who don’t accept invitation in 2 weeks) <br/>
                                                    6. Endose your 1st connection <br/>
                                                    7. Like and comment on 1st connection for get noticed <br/>
                                                    8. Invite to your 1st connection to follow your business page
                                                    
                                                
                                                `}}></span>
                                            </Typography>

                                        </div>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                    <li>
                        <h2 class="level-2 rectangle">
                            <Typography sx={{ fontSize: 22, fontWeight: 600 }}>Not Active on Linkedin 2500 <br /> Email Outreach Campaign</Typography>
                        </h2>
                        <ol class="level-3-wrapper">

                            <li>
                                <ol class="level-4-wrapper">
                                    <li>
                                        <div class="level-4 rectangle">
                                            <Typography variant='body1'>
                                                <span dangerouslySetInnerHTML={{
                                                    __html: `
                                                    
                                                    1. We will gather their business email address <br/>
2. Email marketing campaign with 4 sequential touch points <br/>
3. 1st message, We will introduce your company and will try to understand their business conditions <br/>
4. 2 days later, message 2 will be sent (about your product or service's benefit) <br/>
5. 5 days after they receive message 2, message 3 will be send [Message with more information about your offering and a call-to-action for the prospect (e.g. schedule a call)] <br/>
6. Message 4 will be sent 7 days after they receive Message 3 (to remind them to set up a meeting)

                                                `}}></span>
                                            </Typography>
                                        </div>
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        </Container>
    );
};

export default Flowchart;