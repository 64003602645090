import * as React from 'react';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import card1Image from "./../../images/lead-generation/B2B Contacts With Email Address.jpg"
import card2Image from "./../../images/lead-generation/Global Company List.jpg"
import card3Image from "./../../images/lead-generation/verified leads.png"
import { Container, Grid } from '@mui/material';
function SingleCard({ title, subtitle, image, isHighlight, bgcolor }) {
  return (
    <Card sx={{ maxWidth: 500, mx: "auto" }}>
      {
        isHighlight ?
          <CardContent sx={{
            bgcolor: bgcolor || "primary.main"
          }}>
            {title ?
              <Typography sx={{ fontWeight: 700, fontSize: 50, color: "#fff", textAlign: "center", mb: 0 }} gutterBottom variant="h5" component="div">
                {title}
              </Typography> : null}
            <Typography variant="body2" sx={{ fontSize: 28, color: "#fff", textAlign: "center" }}>
              <span dangerouslySetInnerHTML={{ __html: subtitle }} />
            </Typography>
          </CardContent> :
          <CardContent>
            {title ?
              <Typography sx={{ fontWeight: 700, fontSize: 50 }} color={"primary.main"} gutterBottom variant="h5" component="div">
                {title}
              </Typography> : null}
            <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: 20 }}>
              {subtitle}
            </Typography>
          </CardContent>
      }
      <CardMedia
        component="img"
        height="auto"
        image={image}
        alt="green iguana"
      />
    </Card>
  );
}



const ActionCards = () => {
  return (
    <Container maxWidth="lg" sx={{ my: 5 }}>
      <Grid container spacing={3} >
        <Grid item md={6} xs={12}>
          <SingleCard title={"+210M"} subtitle={"B2B contacts with <br/> email address"} isHighlight image={card1Image} />
        </Grid>
        <Grid item md={6} xs={12}>
          <SingleCard title={"+70M"} bgcolor={"success.main"} subtitle={"Global Company <br/> Profile"} isHighlight image={card2Image} />
        </Grid>
        <Grid item md={6} xs={12} alignItems={"center"} display={"flex"}>
          <Typography variant='h2' sx={{ fontSize: 28 }}>Real-Time verified B2B email list Of key decision makers</Typography>
        </Grid>
        <Grid item md={6} xs={12}>
          <SingleCard subtitle={"Only pay for verified Leads"} image={card3Image} />
        </Grid>
      </Grid>
    </Container>
  );
};

export default ActionCards;