export const salesTableData = [
    {
        title: "Sales qualified leads",
        proPackage: "30-50",
        general: "10-25"
    },
    {
        title: "Targeted LinkedIn profiles extract and viewed",
        proPackage: "10000-12000",
        general: "5000-6000"
    },
    {
        title: "Average connection requests sent and follow them",
        proPackage: "800-1000",
        general: "600-700"
    },
    {
        title: "Expected average connections accepted",
        proPackage: "250-400",
        general: "150-300"
    },
    {
        title: "Re-engagement messages to accepted prospects (3-4 touchpoints)",
        proPackage: "700-1000",
        general: "400-600"
    },
    {
        title: "Targeted cold email sequence marketing to generate responses (4-5 touchpoints) [ Who don’t active on LinkedIn ]",
        proPackage: "1000",
        general: "-"
    },
    {
        title: "Send In-Mails",
        proPackage: "200",
        general: "-"
    },
    {
        title: "Copy creation",
        proPackage: "Yes",
        general: "Yes"
    },
    {
        title: "2 touch point campaign",
        proPackage: "Yes",
        general: "Yes"
    },
    {
        title: "Endorse your 1st connections",
        proPackage: "Yes",
        general: "Yes"
    },
    {
        title: "Invite connections to follow your page",
        proPackage: "Yes",
        general: "Yes"
    },
    {
        title: "Dedicated account manager",
        proPackage: "Yes",
        general: "Yes"
    },
    {
        title: "Access to our CRM",
        proPackage: "Yes",
        general: "Yes"
    }
]



