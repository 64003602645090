import { Box, Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { addPricingRequest } from '../../service/formService';
import MainButton from '../MainButton/MainButton';
import SectionTitle from '../SectionTitle/SectionTitle';

const PricingForm = ({ setCloseModal, packageName, month }) => {
    const [formData, setFormData] = useState({});


    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        addPricingRequest({ ...formData, packageName, month }).then(res => {
            if (res?.data?.success) {
                setCloseModal({ status: true })
                Swal.fire({
                    title: 'Request Send Successfully',
                    icon: "success",
                    timer: 3000,
                })
            }
        }).catch(() => {
            setCloseModal({ status: true })
            Swal.fire({
                title: 'Request Send Failed',
                icon: "error",
                timer: 3000,
            })
        })
    }
    return (
        <Box>
            <SectionTitle sx={{ marginTop: '0px !important', fontSize: '30px !important', marginBottom: '40px !important' }}>We will send a <span>payment request</span> in your email.</SectionTitle>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={5}>
                    <Grid item md={6} xs={12}>
                        <TextField
                            required
                            variant='outlined'
                            fullWidth
                            type={"text"}
                            label="Full Name"
                            name="fullName"
                            onChange={handleChange}
                            placeholder="Enter Your Full Name"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            required
                            variant='outlined'
                            fullWidth
                            type={"email"}
                            label="Email"
                            name="email"
                            onChange={handleChange}
                            placeholder="Enter Your Email"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            required
                            variant='outlined'
                            fullWidth
                            type={"text"}
                            label="Website"
                            name="website"
                            onChange={handleChange}
                            placeholder="Enter Your Website URL"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            variant='outlined'
                            fullWidth
                            type={"text"}
                            label="Company Name"
                            name="company"
                            onChange={handleChange}
                            placeholder="Enter Your Company Name"
                        />
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <TextField
                            required
                            variant='outlined'
                            fullWidth
                            type={"text"}
                            label="State"
                            name="state"
                            onChange={handleChange}
                            placeholder="Enter Your State"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            required
                            variant='outlined'
                            fullWidth
                            type={"text"}
                            label="Country"
                            name="country"
                            onChange={handleChange}
                            placeholder="Enter Your Country"
                        />
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <MainButton type='submit' variant='contained'>Submit</MainButton>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default PricingForm;