
import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import bannerImg from './../../images/lead.png';
import MainButton from '../MainButton/MainButton';
import Navbar from '../Navbar/Navbar';
import { Link } from 'react-router-dom';
const Header = () => {

    return (
        <Box component={'div'} id='home' sx={{ background: "linear-gradient(230deg, #0196c4 20%, #082351 66%)" }}>
            <Navbar />
            <Container>
                <Grid container spacing={2} sx={{ mt: { xs: 5, md: 0 } }}>
                    <Grid item md={6} xs={12} sx={{ display: "flex", alignItems: "center" }}>
                        <Box component={'div'} sx={{ '& a': { textDecoration: "none" } }}>
                            <Typography sx={{ fontSize: 44, color: '#fff', fontWeight: 700 }} variant='h3'>Need B2B Sales Qualified Warm Leads? We Helping B2B Companies Get 10 Leads Per Week.</Typography>
                            <Typography sx={{ fontSize: 17, color: "#fff", my: 3 }} >We generate warm leads and your team close the deals</Typography>
                            <Link to="/outreach" >
                                <MainButton variant='contained'>LinkedIn Outreach</MainButton>
                            </Link>
                        </Box>
                    </Grid>
                    <Grid item md={6} xs={12} sx={{ display: "flex", alignItems: "center" }}>
                        <Box component={'div'} sx={{ '& img': { width: '100%' } }}>
                            <img src={bannerImg} alt="banner" />
                        </Box>
                    </Grid>
                </Grid>
            </Container>
            <Box component={'div'} className="banner-shape"></Box>
        </Box>
    );
};

export default Header;