import * as React from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Slide from '@mui/material/Slide';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import MenuIcon from '@mui/icons-material/Menu';
import Button from '@mui/material/Button';
import { useState } from 'react';
import logo from './../../images/logo.png';
import logoWhite from './../../images/logo-white.png';
import { useLocation } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
function HideOnScroll({ children }) {

    const trigger = useScrollTrigger();
    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

HideOnScroll.propTypes = {
    children: PropTypes.element.isRequired,
};
const drawerWidth = 240;
const navItems = [
    { label: 'Home', id: '/#home' },
    { label: 'Lead-Generation', id: '/lead-generation#_' },
    { label: 'Outreach', id: '/outreach#_' },
];
export default function Navbar(props) {
    const { pathname } = useLocation();
    const [mobileOpen, setMobileOpen] = React.useState(false);
    const [isSticky, setIsSticky] = useState(false);
    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    React.useEffect(() => {
        window.addEventListener("scroll", () => {
            console.log(window.pageXOffset);
            if (window.pageYOffset === 0) {
                setIsSticky(false)
            } else {
                setIsSticky(true)
            }
        })
    }, []);

    const drawer = (
        <Box onClick={handleDrawerToggle} sx={{ textAlign: 'center' }}>
            <Box component={'div'} sx={{ py: 2, '& img': { maxHeight: 50 } }}>
                <img src={logo} alt="" />
            </Box>
            <Divider />
            <List sx={{ '& a': { color: "#0196c4", textDecoration: "none", textTransform: 'uppercase' } }}>
                {navItems.map(({ label, id }) => (
                    <HashLink smooth key={id} to={id}>
                        <ListItem disablePadding>
                            <ListItemButton sx={{ textAlign: 'center' }}>
                                <ListItemText>{label}</ListItemText>
                            </ListItemButton>
                        </ListItem>
                    </HashLink>
                ))}
                <a href="https://docs.google.com/forms/d/1NRtFWoBc4IBbcY7VTBG6BMIVonWvEHKh5YIg57jhC9M/edit" target="_blank" rel="noopener noreferrer">
                    <ListItem disablePadding>
                        <ListItemButton sx={{ textAlign: 'center' }}>
                            <ListItemText>Contact us</ListItemText>
                        </ListItemButton>
                    </ListItem>
                </a>
            </List>
        </Box>
    );
    return (
        <>
            <HideOnScroll {...props}>
                <AppBar component="nav" sx={{ background: isSticky ? '#082351' : pathname === "/" ? "transparent" : "#082351", boxShadow: !isSticky && 'none', py: 1 }}>
                    <Container>
                        <Toolbar sx={{ p: '0px !important' }}>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                edge="start"
                                onClick={handleDrawerToggle}
                                sx={{ mr: 2, display: { md: 'none' } }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Box
                                component="div"
                                sx={{ flexGrow: 1, display: { xs: 'none', md: 'block' }, '& img': { maxHeight: 50 } }}
                            >
                                <HashLink smooth to="/">
                                    <img src={logoWhite} alt="" />
                                </HashLink>
                            </Box>
                            <Box sx={{ display: { xs: 'none', md: 'block' }, '& a': { textDecoration: "none" } }}>
                                {navItems.map(({ label, id }) => (
                                    <HashLink key={id} to={id}>
                                        <Button sx={{ color: '#fff', fontSize: 15 }}>
                                            {label}
                                        </Button>
                                    </HashLink>

                                ))}
                                <a href="https://docs.google.com/forms/d/1NRtFWoBc4IBbcY7VTBG6BMIVonWvEHKh5YIg57jhC9M/edit" target="_blank" rel="noopener noreferrer">
                                    <Button sx={{ color: '#fff', fontSize: 15 }}>Contact us</Button>
                                </a>
                            </Box>
                        </Toolbar>
                    </Container>
                </AppBar>
            </HideOnScroll>
            <Toolbar />

            <Box component="nav">
                <Drawer
                    variant="temporary"
                    open={mobileOpen}
                    onClose={handleDrawerToggle}
                    ModalProps={{
                        keepMounted: true, // Better open performance on mobile.
                    }}
                    sx={{
                        display: { xs: 'block', md: 'none' },
                        '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
                    }}
                >
                    {drawer}
                </Drawer>
            </Box>
        </>
    );
}