import { Container, Grid, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { addContact } from '../../service/formService';
import MainButton from '../MainButton/MainButton';
import SectionTitle from '../SectionTitle/SectionTitle';
import moneyBack from './../../images/money-back.png';
const Contact = () => {
    const [formData, setFormData] = useState({});
    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        addContact(formData).then(res => {
            if (res?.data?.success) {
                Swal.fire({
                    title: 'Message successfully',
                    icon: "success",
                    timer: 3000,
                })
                e.target.reset();
            }
        }).catch(() => {
            Swal.fire({
                title: 'Failed to send message',
                icon: "error",
                timer: 3000,
            })
        })
    }
    return (
        <Container id="contact">
            <SectionTitle><span>Schedule</span> your free consultation</SectionTitle>
            <Typography sx={{ textAlign: 'center', mt: '-20px', mb: 4 }}>B2B Sales Qualified Warm Leads</Typography>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={5}>
                    <Grid item md={4} xs={12}>
                        <TextField
                            required
                            variant='outlined'
                            fullWidth
                            type={"text"}
                            label="Name"
                            name="name"
                            onChange={handleChange}
                            placeholder="Enter Your Name"
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            required
                            variant='outlined'
                            fullWidth
                            type={"email"}
                            label="Email"
                            name="email"
                            onChange={handleChange}
                            placeholder="Enter Your Email"
                        />
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <TextField
                            required
                            variant='outlined'
                            fullWidth
                            type={"text"}
                            name="website"
                            onChange={handleChange}
                            label="Your Website"
                            placeholder="Enter Your Website URL"
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            required
                            multiline
                            rows={4}
                            variant='outlined'
                            fullWidth
                            type={"text"}
                            name="message"
                            onChange={handleChange}
                            label="Message"
                            placeholder="How can we help you?"
                        />
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <MainButton type='submit' variant='contained'>Submit</MainButton>
                    </Grid>
                </Grid>
            </form>
            <Box sx={{ textAlign: 'center', mt: 8, mb: 5 }}>
                <img src={moneyBack} alt="" />
                <Typography>We will identify and reach your ideal audience within 14 days or your money back!</Typography>
            </Box>
        </Container>
    );
};

export default Contact;