export const FAQData = [
    {
        question: "What exactly is B2B Linked Up?",
        answer: `1. We assist you, Create qualified target lists of your key decision-makers. <br/>
        2. We create proven cold outreach messages that actually get responded to<br/>
        3. We Send 1000s of direct and personal messages to decision-makers you trust<br/>
        4. We Generate positive responses straight to your LinkedIn inbox for you and you close the deal`
    },
    {
        question: "How Many Leads Can I Get Per Month?",
        answer: "We tell our clients to expect anywhere from 1-10 leads every week. Keep in mind that these are high-quality leads because they are professionals who have previously heard your pitch and agreed to meet with you."
    },
    {
        question: "Can B2B Linked Up help me optimize my LinkedIn profile?",
        answer: `Yes. <br/>
        If you sign up for B2B Linked Up, as part of the onboarding process, we will optimize your LinkedIn account headline and provide profile image recommendations to improve your response rates.`
    },
    {
        question: "Does B2B Linked Up need access to my LinkedIn account?",
        answer: `Yes. <br/>
        The fact that the outreach comes directly from you or someone else at your company is what makes our lead generation initiatives so powerful. Your favorable response rate will skyrocket if adding that extra personal touch.`
    },
    {
        question: "Is there a risk of my profile being suspended by LinkedIn if I use B2B Linked Up?",
        answer: `No. <br/>
        B2B Linked Up always stays under LinkedIn’s daily maximum limit and never sends spam messages. B2B Linked Up has never had a client’s profile gets suspended by LinkedIn.`
    },
    {
        question: "Does B2B Linked Up respond to prospects for me?",
        answer: `No.<br/>
        Clients have proven to be the most adept at handling reactions.`
    },
    {
        question: "Does B2B Linked Up send messages to my current 1st degree network?",
        answer: `No.<br/>
        Prior to commencing your LinkedIn outreach campaign, we will exclude all of your present connections. Unless if you want us to message your 1st-degree network. Then we can.`
    },
    {
        question: "Do I need to LinkedIn Sales Navigator to use B2B Linked Up?",
        answer: "Yes, the account being used for B2B Linked Up will need to have LinkedIn Sales Navigator. We recommend LinkedIn Sales Navigator Professional because it is more affordable. We will make your LinkedIn profile Sales Navigator. Don’t worry about it."
    },
    {
        question: "What is an InMail?",
        answer: "LinkedIn's internal messaging system is called InMail. It can be used to send targeted messages to potential leads."
    },
    {
        question: "On LinkedIn, what types of leads can I target?",
        answer: `LinkedIn may be used to target almost any type of professional. Age and gender are the only qualities you can't target. <br/> 
        Through Sales Navigator on LinkedIn, we may target the following demographic characteristics: <br/>
        <strong>Geography</strong><br/>
        <strong>Industry</strong><br/>
        <strong>Title</strong><br/>
        <strong>Company Headcount/ Size</strong><br/>
        Relationship<br/>
        School<br/>
        Profile Language<br/>
        Seniority Level<br/>
        Years in Current Position<br/>
        Years at Current Company<br/>
        Function<br/>
        Years of Experience<br/>
        Company<br/>
        Company Type<br/>
        Past Company
        `
    },
    {
        question: "How Long Does It Take To See Results?",
        answer: "The advantage of a direct response campaign is that you can see results right away. That is, you will know whether or not this campaign will be successful within the first two weeks."
    },
    {
        question: "How Do You Track Performance?",
        answer: `All important LinkedIn performance indicators will be updated once a week. For simple access, these metrics will be divided out in a Google Sheet. The following are some of the metrics we monitor:
        Sent Connections <br/>
        Accepted Connections<br/>
        Follow-ups have been sent.<br/>
        Meetings have been scheduled.`
    }
]