import { Grid, TextField } from '@mui/material';
import React, { useState } from 'react';
import Swal from 'sweetalert2';
import { addPriceRequest } from '../../service/formService';
import MainButton from '../MainButton/MainButton';
import SectionTitle from '../SectionTitle/SectionTitle';

const RequestPricingForm = ({ packageName, setCloseModal }) => {
    const [formData, setFormData] = useState({});
    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        addPriceRequest({ ...formData, packageName }).then(res => {
            if (res?.data?.success) {
                setCloseModal({ status: true })
                Swal.fire({
                    title: 'Request placed successfully',
                    icon: "success",
                    timer: 3000,
                })
            }
        }).catch(() => {
            setCloseModal({ status: true })
            Swal.fire({
                title: 'Request placed Failed',
                icon: "error",
                timer: 3000,
            })
        })
    }
    return (
        <>
            <SectionTitle sx={{ marginTop: '0px !important' }}><span>Pricing</span> Request</SectionTitle>
            <form onSubmit={handleSubmit}>
                <Grid container spacing={5}>
                    <Grid item md={6} xs={12}>
                        <TextField
                            required
                            variant='outlined'
                            fullWidth
                            type={"text"}
                            name="fullName"
                            onChange={handleChange}
                            label="Full Name"
                            placeholder="Enter Your Full Name"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            required
                            variant='outlined'
                            fullWidth
                            name="website"
                            onChange={handleChange}
                            type={"text"}
                            label="Website"
                            placeholder="Enter Your Website URL"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            required
                            variant='outlined'
                            name="email"
                            onChange={handleChange}
                            fullWidth
                            type={"email"}
                            label="Email"
                            placeholder="Enter Your Email"
                        />
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <TextField
                            required
                            variant='outlined'
                            name="industry"
                            onChange={handleChange}
                            fullWidth
                            type={"text"}
                            label="Industry"
                            placeholder="Enter Your Industry"
                        />
                    </Grid>
                    <Grid item xs={12} textAlign="center">
                        <MainButton type='submit' variant='contained'>Submit</MainButton>
                    </Grid>
                </Grid>
            </form>
        </>
    );
};

export default RequestPricingForm;