import styled from "@emotion/styled";
import { Button } from "@mui/material";


const MainButton = styled(Button)({
    backgroundColor: '#0196c4',
    fontSize: 18,
    padding: '10px 30px',
    '&:hover': {
        backgroundColor: '#0b6d8a'
    }
})
export default MainButton