import * as React from 'react';
import Backdrop from '@mui/material/Backdrop';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import MainButton from '../../MainButton/MainButton';
import CloseIcon from '@mui/icons-material/Close';
import { IconButton } from '@mui/material';
const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: 800,
    maxHeight: '90vh',
    overflowY: 'auto',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    outline: 0
};

export default function CustomModal({ children, title = "Open Modal", editMode, openModal, closeModal, buttonStyle }) {
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);
    React.useEffect(() => {
        if (openModal) {
            setOpen(true)
        }
    }, [openModal])
    React.useEffect(() => {
        if (closeModal?.status) {
            setOpen(false)
        }
    }, [closeModal])
    return (
        <div>
            {!editMode && <MainButton sx={buttonStyle} onClick={handleOpen} variant="contained">{title}</MainButton>}
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <Box sx={style}>
                        <Box sx={{ textAlign: 'right' }}>
                            <IconButton onClick={handleClose}>
                                <CloseIcon />
                            </IconButton>
                        </Box>
                        {children}
                    </Box>
                </Fade>
            </Modal>
        </div>
    );
}


