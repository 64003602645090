import { Box, Container } from '@mui/material';
import React from 'react';
import Header from '../components/LeadGeneration/Header';
import ActionCards from '../components/LeadGeneration/ActionCards';
import WhoYouAre from '../components/LeadGeneration/WhoYouAre';

const LeadGeneration = () => {
    return (
        <Box id="_">
            <Header />
            <Container>
                <ActionCards />
                <WhoYouAre />
            </Container>
        </Box>
    );
};

export default LeadGeneration;