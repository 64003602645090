import React from 'react';
import ActualResult from '../components/ActualResult/ActualResult';
import Contact from '../components/Contact/Contact';
import FAQ from '../components/FAQ/FAQ';
import Header from '../components/header/Header';
import HowWeWork from '../components/howWeWork/HowWeWork';
import OurSecret from '../components/OurSecret/OurSecret';
import PopupForm from '../components/PopupForm/PopupForm';
import SalesTable from '../components/SalesTable/SalesTable';
import SuccessSteps from '../components/SuccessSteps/SuccessSteps';
import Testimonials from '../components/Testimonials/Testimonials';
import WhatToExpect from '../components/whatToExpect/WhatToExpect';
import TargetedCustomer from '../components/TargetedCustomer/TargetedCustomer';

const Home = () => {
    return (
        <>
            <Header />
            <TargetedCustomer />
            <HowWeWork />
            <OurSecret />
            <WhatToExpect />
            <SuccessSteps />
            <ActualResult />
            <SalesTable />
            <Testimonials />
            <FAQ />
            <Contact />
            <PopupForm />
        </>
    );
};

export default Home;