import * as React from 'react';
import { styled } from '@mui/material/styles';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
))(({ theme }) => ({
    borderBottom: `1px solid ${theme.palette.divider}`,
    '&:before': {
        display: 'none',
    },
}));

const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
        expandIcon={<ArrowRightIcon />}
        {...props}
    />
))(({ theme }) => ({
    backgroundColor: "#fff",
    flexDirection: 'row-reverse',
    color: "#0196c4",
    '& .MuiTypography-root': {
        fontFamily: "Montserrat",
        fontWeight: 600
    },
    '& .Mui-expanded': {
        color: "#d00661",
    },
    '& .MuiAccordionSummary-expandIconWrapper': {
        color: "#0196c4"
    },
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
        transform: 'rotate(90deg)',
        color: "#d00661",
    },
    '& .MuiAccordionSummary-content': {
        marginLeft: theme.spacing(1),
    },
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    color: "#7a7a7a"
    // borderTop: '1px solid rgba(0, 0, 0, .125)',
}));

export default function CustomAccordion({ data }) {
    const [expanded, setExpanded] = React.useState('panel1');

    const handleChange = (panel) => (event, newExpanded) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <>
            {
                data?.map(({ question, answer }, i) => (
                    <Accordion expanded={expanded === `panel${i + 1}`} onChange={handleChange(`panel${i + 1}`)}>
                        <AccordionSummary aria-controls={`panel${i + 1}d-content`} id={`panel${i + 1}d-header`}>
                            <Typography>{question}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography dangerouslySetInnerHTML={{ __html: answer }} />
                        </AccordionDetails>
                    </Accordion>
                ))
            }
        </>
    );
}
