import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

// import required modules
import { EffectCoverflow, Pagination } from "swiper";

export default function CustomCarousel({ slides }) {
    return (
        <>
            <Swiper
                style={{ padding: "20px 0" }}
                effect={"coverflow"}
                grabCursor={true}
                centeredSlides={true}
                slidesPerView={3}
                onActiveIndexChange={2}
                roundLengths={true}
                loop={true}
                loopAdditionalSlides={30}
                breakpoints={{
                    0: {
                        slidesPerView: 1,
                        centeredSlides: true,
                    },
                    768: {
                        slidesPerView: 2,
                        centeredSlides: false
                    },
                    1024: {
                        slidesPerView: 3,
                        centeredSlides: true
                    },
                }}
                coverflowEffect={{
                    // rotate: 30,
                    // stretch: 0,
                    // depth: 100,
                    // modifier: 1,
                    slideShadows: false,
                }}
                pagination={true}
                modules={[EffectCoverflow, Pagination]}
                className="mySwiper"
            >
                {
                    slides?.map((slide, index) => <SwiperSlide key={index}>{slide}
                    </SwiperSlide>)
                }
            </Swiper>
        </>
    );
}

