import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import Footer from './components/Footer/Footer';
import Dashboard from './pages/Dashboard';
import Home from './pages/Home';
import OurSecret from './pages/OurSecret';
import OutreachQuestionnaire from './pages/OutreachQuestionnaire';
import Pricing from './pages/Pricing';
import LeadGeneration from './pages/LeadGeneration';

const App = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/our-pricing" component={Pricing} />
        <Route exact path="/outreach" component={OurSecret} />
        <Route exact path="/lead-generation" component={LeadGeneration} />
        <Route exact path="/outreach-questionnaire" component={OutreachQuestionnaire} />
        <Route exact path={`/dashboard/${process.env.REACT_APP_DASHBOARD_PASSWORD}`} component={Dashboard} />
        <Route path="*" component={Home} />
      </Switch>
      <Footer />
    </BrowserRouter>
  );
};

export default App;