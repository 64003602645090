import { Box, Container } from '@mui/material';
import React, { useState } from 'react';
import { salesTableData } from '../../data/salestableData';
import CustomModal from '../custom/CustomModal/CustomModal';
import RequestPricingForm from '../RequestPricingForm/RequestPricingForm';
import SectionTitle from '../SectionTitle/SectionTitle';
import './SalesTable.scss';

const SalesTable = () => {
    const [closeModal, setCloseModal] = useState({ status: false });
    return (
        <Container id="pricing">
            <SectionTitle>Minimum Cost — <span>Highest Sales</span></SectionTitle>
            <div className="social-media-packages">
                <div className="custom-table">
                    <table>
                        <tbody>
                            <tr className="table-head text-white no-border">
                                <th className="bg-blue">B2B Outreach Solutions</th>
                                <th className="bg-pink">LinkedIn+Email <br /> Outreach <br /> pro Package</th>
                                <th className="bg-yellow">LinkedIn <br /> Outreach <br /> basic Package</th>
                            </tr>
                            {
                                salesTableData.map(({ title, proPackage, general }, i) => (
                                    <tr key={i}>
                                        <td>{i === 0 ? <strong>{title}</strong> : title}</td>
                                        <td className="bg-pink-100">{i === 0 ? <strong>{proPackage}</strong> : proPackage}</td>
                                        <td className="bg-yellow-100">{i === 0 ? <strong>{general}</strong> : general}</td>
                                    </tr>
                                ))
                            }

                            <tr>
                                <td>
                                    <Box component={'p'} sx={{ fontSize: 25, fontWeight: 600, color: "#0196c4" }}>
                                        {'Click here >>>'}
                                    </Box>
                                </td>
                                <td>
                                    <CustomModal title='Pricing' closeModal={closeModal}>
                                        <RequestPricingForm setCloseModal={setCloseModal} packageName="pro-package" />
                                    </CustomModal>
                                </td>
                                <td>
                                    <CustomModal title='Pricing' closeModal={closeModal}>
                                        <RequestPricingForm setCloseModal={setCloseModal} packageName="basic-package" />
                                    </CustomModal>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="package-note">
                    <p>**    All our packages are for each Linkedin account.</p>
                </div>
            </div>
        </Container>
    );
};

export default SalesTable;