import { Box, Container, Grid, Link, List, ListItem, ListItemText, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import logo from './../../images/logo-white.png';
import SocialMediaButtons from 'react-social-media-buttons';
import MainButton from '../MainButton/MainButton';
import Swal from 'sweetalert2';
import { addNewsLetter } from '../../service/formService';
import { HashLink } from 'react-router-hash-link';
const navItems = [
    { label: 'B2B Lead Generation', id: '/#outreach' },
    { label: 'Outreach', id: '/outreach#_' },
    { label: 'FAQ', id: '/#faq' },
];
const Footer = () => {
    const [email, setEmail] = useState("");

    const handleChange = e => {
        setEmail(e.target.value)
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        if (!email) return;
        addNewsLetter({ email }).then(res => {
            if (res?.data?.success) {
                e.target.reset();
                Swal.fire({
                    title: 'Success',
                    icon: "success",
                    timer: 3000,
                })
            }
        }).catch(() => {
            Swal.fire({
                title: 'Something went wrong!',
                icon: "error",
                timer: 3000,
            })
        })
    }
    return (
        <Box sx={{ background: "linear-gradient(230deg, #0196c4 20%, #082351 66%)" }}>
            <Box component={'div'} className="footer-shape"></Box>
            <Container sx={{ pt: 6 }}>
                <Grid container spacing={3}>
                    <Grid item md={5} xs={12}>
                        <Box component={'div'} sx={{ '& img': { maxHeight: 60 } }}>
                            <img src={logo} alt="" />
                            <Typography sx={{ color: '#fff' }}>We helping B2B Companies Get at least 1 Lead Per Day.</Typography>
                        </Box>
                        <Box sx={{
                            mt: 5,
                            '& a': {
                                transition: '.3s ease-in-out',
                                '&:hover': { '& svg': { '& g': { fill: '#fff !important' } }, backgroundColor: "#0196c4 !important" }
                            }
                        }}>
                            <SocialMediaButtons
                                links={['https://www.linkedin.com/company/b2b-linked-up/about/', 'https://www.facebook.com/b2blinkedup	', 'https://twitter.com/B2bUp', 'https://www.instagram.com/b2blinkedup/', 'https://www.youtube.com/channel/UCrhXBoh46Din_AUBlMEBIIA']}
                                buttonStyle={{ width: '45px', height: '45px', margin: '0px 24px 0 0', backgroundColor: 'transparent', border: '2px solid #0196c4' }}
                                iconStyle={{ color: '#fff' }}
                                openNewTab={true}
                            />
                        </Box>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Box component={'div'}>
                            <List sx={{ '& a': { color: "#fff", textDecoration: "none" } }}>
                                <ListItem disablePadding>
                                    <ListItemText sx={{ textTransform: 'uppercase', color: '#0196c4', fontSize: 20 }}>Quick links</ListItemText>
                                </ListItem>
                                {
                                    navItems.map(({ label, id }) => (
                                        <HashLink key={id} to={id}>
                                            <ListItem disablePadding>
                                                <ListItemText>{label}</ListItemText>
                                            </ListItem>
                                        </HashLink>
                                    ))
                                }
                                <a href="https://docs.google.com/forms/d/1NRtFWoBc4IBbcY7VTBG6BMIVonWvEHKh5YIg57jhC9M/edit" target="_blank" rel="noopener noreferrer">
                                    <ListItem disablePadding>
                                        <ListItemText>Contact us</ListItemText>
                                    </ListItem>
                                </a>

                            </List>
                        </Box>
                    </Grid>
                    <Grid item md={4} xs={12}>
                        <Box component={'div'} sx={{ border: '4px solid #000', px: 3, py: 2, backgroundColor: "#fff" }}>
                            <Typography sx={{ textAlign: 'center', fontSize: 19, '& span': { fontSize: 30, color: '#0196c4', fontWeight: 600 } }}> <span>Refer</span> your colleague or friends</Typography>
                            <form onSubmit={handleSubmit}>
                                <TextField
                                    variant="outlined"
                                    fullWidth
                                    name="email"
                                    margin='normal'
                                    label="Referral's Email Address"
                                    type="email"
                                    placeholder="Enter Referral's Email"
                                    onChange={handleChange}
                                />
                                <MainButton type='submit' sx={{ mt: 1 }} variant='contained' fullWidth>Hit me</MainButton>
                            </form>
                        </Box>
                    </Grid>
                </Grid>
                <Grid container spacing={2} sx={{ py: 3, mt: 5 }}>
                    <Grid item md={3} xs={12}>
                    </Grid>
                    <Grid item md={6} xs={12}>
                        <Typography sx={{ textAlign: "center", fontSize: 12, color: '#ddd' }} variant='body1'>Copyright &copy; {new Date().getFullYear()} All right reserved by B2B Linked Up </Typography>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Typography sx={{ textAlign: "center", fontSize: 14, color: '#ddd' }} variant='body1'>developed by <Link sx={{ color: '#fff' }} href="https://dvlpralamin.netlify.app/" target="_blank" rel="noopener noreferrer">dvlpralamin</Link> </Typography>
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default Footer;