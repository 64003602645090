import people5 from './../images/persons/person (1).png';
import people1 from './../images/persons/person (2).png';
import people2 from './../images/persons/person (3).png';
import people4 from './../images/persons/person (5).png';
export const ourSecretData = [
    {
        secrets: [
            {
                title: "Identify prospects using Sales Navigator",
                body: "We will find the right prospect that needs what you’re offering. Each lead is also vetted manually by your dedicated LinkedIn Manager to ensure accuracy."
            },
            {
                title: "Connect request sent to targeted prospects",
                body: "20-50 every weekday."
            },
            {
                title: "Prospect accepts connect request",
                body: "Average 30-40% acceptance rate."
            },
            {
                title: "The second message was sent the next day.",
                body: "Message introducing you and your company to the prospect."
            },
        ],
        persons: [
            {
                name: "Robert",
                image: people5,
                designation: "Content writer"
            },
            {
                name: "Filippo",
                image: people4,
                designation: "Operation manager"
            },
        ]
    },
    {
        secrets: [
            {
                title: "7 days later, message 3 is sent",
                body: "Message with more information about your offering and a call-to-action for the prospect (e.g. schedule a call)."
            },
            {
                title: "Posts on LinkedIn",
                body: "We write posts for your LinkedIn profile and publish them. Your posts help to develop trust and nurture your leads."
            },
            {
                title: "Your LinkedIn inbox fills up with new business inquiries.",
                body: "As soon as the prospect responds, our communications comes to a halt. It is your responsibility to keep an eye on your LinkedIn Inbox and reply to inquiries."
            },
            {
                title: "Reinvigorate your relationships",
                body: "We re-engage unresponsive connections with value messaging geared to illustrate your expertise one month after they receive Message 3."
            },
        ],
        persons: [

            {
                name: "Nicole",
                image: people2,
                designation: "Linkedin manager"
            },
            {
                name: "Alex",
                image: people1,
                designation: "Linkedin manager"
            },
        ]
    },
]