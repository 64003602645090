import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { Badge, Container, Grid, Typography } from '@mui/material';
import PricingCard from '../components/Pricing/PricingCard';
import Navbar from '../components/Navbar/Navbar';

const pricingData = [
    [
        {
            packageName: "Pro Package",
            price: 900,
            total: 900,
            month: 1
        },
        {
            packageName: "Basic Package",
            price: 700,
            total: 700,
            month: 1
        },
    ],
    [
        {
            packageName: "Pro Package",
            price: 765,
            total: 1530,
            month: 2
        },
        {
            packageName: "Basic Package",
            price: 595,
            total: 1190,
            month: 2
        },
    ],
    [
        {
            packageName: "Pro Package",
            price: 630,
            total: 2520,
            month: 4
        },
        {
            packageName: "Basic Package",
            price: 490,
            total: 1960,
            month: 4
        },
    ],
]


function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    {children}
                </Box>
            )}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function Pricing() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    return (
        <>
            <Navbar />
            <Container maxWidth="md">
                <Box sx={{ width: '100%', mt: 10 }}>
                    <Typography variant='h4' textAlign={"center"} mb={5} sx={{ fontWeight: 600, }}> Linkedin Outreach Cost</Typography>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange} centered>
                            <Tab sx={{ fontSize: 20, fontWeight: 600, textTransform: "capitalize", fontFamily: "Montserrat" }}
                                label={<Box component={"span"} mt={1}>1 Month</Box>}
                                {...a11yProps(0)} />
                            <Tab sx={{ fontSize: 20, fontWeight: 600, textTransform: "capitalize", fontFamily: "Montserrat" }}
                                label={
                                    <Badge badgeContent={"-15%"} color="secondary">
                                        <Box component={"span"} mt={1}>2 Months</Box>
                                    </Badge>
                                }
                                {...a11yProps(0)} />
                            <Tab sx={{ fontSize: 20, fontWeight: 600, textTransform: "capitalize", fontFamily: "Montserrat" }}
                                label={
                                    <Badge badgeContent={"-30%"} color="secondary">
                                        <Box component={"span"} mt={1}>4 Months</Box>
                                    </Badge>
                                }
                                {...a11yProps(0)} />
                        </Tabs>
                    </Box>
                    {
                        pricingData.map((item, i) => (
                            <TabPanel value={value} index={i} key={i}>
                                <Grid container spacing={5}>
                                    {
                                        item.map((single, index) => (
                                            <Grid item sm={6} xs={12} key={index + 5}>
                                                <PricingCard single={single} />
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </TabPanel>
                        ))
                    }
                    <Typography variant='h5' textAlign={"center"} sx={{ color: "secondary.main", mt: 8 }}>All the packages are for each linkedin account
                    </Typography>
                </Box>
            </Container>
        </>
    );
}
