import { Alert, Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';
import MainButton from '../MainButton/MainButton';

const WhoYouAre = () => {
    return (
        <Container maxWidth="md">
            <SectionTitle><span>Who</span> are you?</SectionTitle>
            <Grid container spacing={2}>
                {
                    ['Database Software Owner', 'B2B Marketer', 'SAAS Sales Manager', 'Database Reseller'].map((text, i) => (
                        <Grid key={i} item md={6} xs={12}>
                            <Alert sx={{ fontSize: 20 }} color={["success", 'warning', 'error', 'info'][i]} icon={false}>{text}</Alert>
                        </Grid>
                    ))
                }
            </Grid>
            <Typography sx={{ textAlign: "center", fontSize: 24, color: "primary.main", mt: 3 }}>  Yes, this is the right place for you   </Typography>
            <Box sx={{ textAlign: "center", mt: 2 }}>
                <a href="https://docs.google.com/forms/d/1NRtFWoBc4IBbcY7VTBG6BMIVonWvEHKh5YIg57jhC9M/edit" target="_blank" rel="noopener noreferrer">
                    <MainButton variant='contained' sx={{ textTransform: "capitalize" }}>Contact Me</MainButton></a>
            </Box>
        </Container>
    );
};

export default WhoYouAre;