import { Box } from '@mui/material';
import React from 'react';
import Navbar from '../components/Navbar/Navbar';

const OutreachQuestionnaire = () => {
    return (
        <Box id="outreach">
            <Navbar />
            <Box sx={{ '& iframe': { height: '3600px', mt: 2 } }}>
                <iframe title='Outreach Questionnaire' src="https://docs.google.com/forms/d/e/1FAIpQLSeZo_dJo88XfyMZJsUq8M6HsO4wOfxFijApHpeB8Nn_2x1XrA/viewform?embedded=true" width="100%" frameborder="0" marginheight="0" marginwidth="0" />
            </Box>
        </Box>
    );
};

export default OutreachQuestionnaire;