import { Grid, TextField, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useEffect } from 'react';
import Swal from 'sweetalert2';
import { addPopup } from '../../service/formService';
import CustomModal from '../custom/CustomModal/CustomModal';
import MainButton from '../MainButton/MainButton';
import SectionTitle from '../SectionTitle/SectionTitle';

const PopupForm = () => {
    const [openModal, setOpenModal] = useState(false);
    const [formData, setFormData] = useState({});
    const [closeModal, setCloseModal] = useState({ status: false });
    useEffect(() => {
        setTimeout(() => {
            setOpenModal(true)
        }, 65000);
    }, [])

    const handleChange = e => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    }
    const handleSubmit = (e) => {
        e.preventDefault();
        addPopup(formData).then(res => {
            if (res?.data?.success) {
                setCloseModal({ status: true })
                Swal.fire({
                    title: 'Message Send Successfully',
                    icon: "success",
                    timer: 3000,
                })
            }
        }).catch(() => {
            setCloseModal({ status: true })
            Swal.fire({
                title: 'Message Send Failed',
                icon: "error",
                timer: 3000,
            })
        })
    }
    return (
        <div>
            <CustomModal editMode={true} openModal={openModal} closeModal={closeModal}>
                <SectionTitle sx={{ marginTop: '0px !important', fontSize: '30px !important', marginBottom: '15px !important' }}>Want to <span>increase sale</span> from LinkedIn?</SectionTitle>
                <Typography sx={{ textAlign: 'center', mb: 5 }}>Grow your business with a daily flow of quality leads</Typography>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={5}>
                        <Grid item md={6} xs={12}>
                            <TextField
                                required
                                variant='outlined'
                                fullWidth
                                type={"text"}
                                label="First Name"
                                name="firstName"
                                onChange={handleChange}
                                placeholder="Enter Your First Name"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                required
                                variant='outlined'
                                fullWidth
                                type={"text"}
                                label="Last Name"
                                name="lastName"
                                onChange={handleChange}
                                placeholder="Enter Your Last Name"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                required
                                variant='outlined'
                                fullWidth
                                type={"email"}
                                label="Email"
                                name="email"
                                onChange={handleChange}
                                placeholder="Enter Your Email"
                            />
                        </Grid>
                        <Grid item md={6} xs={12}>
                            <TextField
                                required
                                variant='outlined'
                                fullWidth
                                type={"text"}
                                label="Website"
                                name="website"
                                onChange={handleChange}
                                placeholder="Enter Your Website URL"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                multiline
                                rows={4}
                                required
                                variant='outlined'
                                fullWidth
                                type={"text"}
                                label="Service"
                                name="service"
                                onChange={handleChange}
                                placeholder="Provide a short Brief about your Service"
                            />
                        </Grid>
                        <Grid item xs={12} textAlign="center">
                            <MainButton type='submit' variant='contained'>Submit</MainButton>
                        </Grid>
                    </Grid>
                </form>
            </CustomModal>
        </div>
    );
};

export default PopupForm;