import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import handShake from "./../../images/hand-shake.svg";
const TargetedCustomer = () => {
    return (
        <Box>
            <Container>
                <div className="flowchart">
                    <h1 class="level-1 rectangle">
                        <Typography sx={{ fontSize: 30, fontWeight: 700 }}>Targeted Customer List</Typography>
                    </h1>
                    <Box component={"ol"} class="level-2-wrapper">
                        <Box component={"li"} sx={{ background: "white", zIndex: 2 }}>
                            <Box class="rectangle">
                                <Typography sx={{ fontSize: 24, textAlign: "center", fontWeight: 600, color: "primary.main" }}>Linkedin Outreach</Typography>
                            </Box>
                        </Box>
                        <Box component={"li"} sx={{ background: "white", zIndex: 2 }}>
                            <Box class="rectangle" >
                                <Typography sx={{ fontSize: 24, textAlign: "center", fontWeight: 600, color: "primary.main" }}> Email Marketing</Typography>
                            </Box>
                        </Box>
                    </Box>
                </div>
                <Box sx={{ maxWidth: 700, mx: "auto", mb: 15 }}>
                    <Box sx={{ display: { md: "block", xs: "none" }, mx: "auto", height: 350, width: 350, mt: "-200px", borderRight: "2px solid #000", borderBottom: "2px solid #000", transform: "rotate(45deg)" }}></Box>
                    <Box component={"img"} sx={{ width: "100%" }} src={handShake} alt="hand-shake" />
                    <Typography variant='h2' sx={{ mt: -8, mb: 8, textAlign: "center", fontWeight: 700, color: "primary.main" }}>Sales</Typography>
                </Box>
            </Container>
        </Box>
    );
};

export default TargetedCustomer;