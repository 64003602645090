import { Box, Container, Grid, IconButton, Typography } from '@mui/material';
import React from 'react';
import { ourSecretData } from '../../data/ourSecretData';
import SectionTitle from '../SectionTitle/SectionTitle';
import DownArrow from '@mui/icons-material/KeyboardDoubleArrowDown';
const OurSecret = () => {
    return (
        <Container id="outreach">
            <SectionTitle><span>LinkedIn</span> Outreach</SectionTitle>
            <Grid container spacing={7}>
                {
                    ourSecretData.map(({ secrets, persons }, i) => (
                        <>
                            <Grid item order={i % 2 === 0 ? 1 : 2} md={6} xs={12}>
                                {
                                    secrets.map(({ title, body }) => (
                                        <Box component={'div'} sx={{ display: 'flex', alignItems: "start", my: 2 }}>
                                            <IconButton sx={{ bgcolor: '#004964', color: '#fff', mr: 2, mt: 1, '&:hover': { bgcolor: '#004964' } }}>
                                                <DownArrow />
                                            </IconButton>
                                            <Box component={"div"}>
                                                <Typography sx={{ fontSize: 20, fontWeight: 600, fontFamily: "Montserrat" }}>{title}</Typography>
                                                <Typography sx={{ fontSize: 18, fontFamily: "Montserrat" }}>{body}</Typography>
                                            </Box>
                                        </Box>
                                    ))
                                }
                            </Grid>
                            <Grid order={i % 2 === 0 ? 2 : 1} item md={6} xs={12} sx={{ display: 'grid', placeItems: "center" }}>
                                <Grid container spacing={8} >
                                    {
                                        persons.map(({ name, designation, image }) => (

                                            <Grid item sm={6} sx={12}>
                                                <Box component={'div'} sx={{ '& img': { maxWidth: '100%' }, textAlign: 'center' }}>
                                                    <img src={image} alt="" />
                                                    <Typography sx={{ color: "#004964", fontWeight: 600, fontFamily: "Montserrat" }}>{name}</Typography>
                                                    <Typography sx={{ fontStyle: "italic" }}>{designation}</Typography>
                                                </Box>
                                            </Grid>
                                        ))
                                    }
                                </Grid>
                            </Grid>
                        </>
                    ))
                }
            </Grid>
        </Container>
    );
};

export default OurSecret;