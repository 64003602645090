import { Box, Container, Typography } from '@mui/material';
import React from 'react';
import Flowchart from '../components/Flowchart/Flowchart';
import Navbar from '../components/Navbar/Navbar';
import OutreachQuestionsButton from '../components/OutreachQuestionnaire/OutreachQuestionsButton';

const OurSecret = () => {
    return (
        <Box id="_">
            <Navbar />
            <Container>
                <Box mt={5}>
                    <Typography variant='h3' sx={{ textAlign: "center", fontSize: 45, fontWeight: 600 }}> Linkedin Outreach Secret</Typography>
                    <Typography variant='h4' sx={{ textAlign: "center", my: 3, fontWeight: 600, color: "primary.main" }}>Working Process</Typography>

                    <Typography variant='h5' sx={{ fontSize: 20 }}>
                        <strong>Step 1:</strong>  Extract and view targeted LinkedIn profiles
                    </Typography>
                    <Typography variant='h5' sx={{ fontSize: 20, mt: 3 }}>
                        <strong>Step 2:</strong>  We will identify the best prospects and contact both those who are active on LinkedIn and those who are not.
                    </Typography>
                </Box>
            </Container>
            <Flowchart />
            <OutreachQuestionsButton />
        </Box>
    );
};

export default OurSecret;