import { Box, Container, Grid, Typography } from '@mui/material';
import React from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';
import thingkingImg from './../../images/thingking.png';
const HowWeWork = () => {
    return (
        <Container sx={{ mt: 8 }} className='how-we-work-component'>
            <SectionTitle><span>How</span> we work</SectionTitle>
            <Grid container spacing={4}>
                <Grid item md={6} xs={12}>
                    <Typography sx={{ fontSize: 24, color: '#0196c4' }}>How B2BLinkedUp will work for my Business?</Typography>
                    <Box>
                        <img style={{ width: '100%', maxWidth: 400, marginTop: 30 }} src={thingkingImg} alt="" />
                    </Box>
                </Grid>
                <Grid item md={6} xs={12}>
                    <Typography variant='h5' sx={{ textAlign: 'center', fontSize: 27 }}>20-50 sales qualified leads per month using ultra-personalized LinkedIn + email outreach, <br /> <strong>or your money back</strong></Typography>
                    <Box sx={{ mt: 3 }}>
                        <iframe width="100%" height="315" src="https://www.youtube.com/embed/bIvTGY7OUqQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                    </Box>
                </Grid>
            </Grid>
        </Container>
    );
};

export default HowWeWork;