import { Box, Container } from '@mui/material';
import React from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';
import actualImg from './../../images/result.png';
const ActualResult = () => {
    return (
        <Container>
            <SectionTitle><span>Actual</span> client results</SectionTitle>
            <Box component={'div'} sx={{ textAlign: 'center', '& img': { maxWidth: "100%" } }}>
                <img src={actualImg} alt="" />
            </Box>
        </Container>
    );
};

export default ActualResult;