import { Box, Container, Grid, Link, Typography } from '@mui/material';
import React from 'react';
import SectionTitle from '../SectionTitle/SectionTitle';
import handShake from './../../images/handshake.png';
import search from './../../images/search.png';
import send from './../../images/send.png';
import conversation from './../../images/conversation.png';
import StepCard from '../StepCard/StepCard';
import MainButton from '../MainButton/MainButton';
import moneyBack from './../../images/money-back.png';
const steps = [
    {
        icon: search,
        text: '1. Identify a list of your ideal prospects on LinkedIn.'
    },
    {
        icon: conversation,
        text: '2. Setting up Multi-Channel Outreach (LinkedIn + Email)'
    },
    {
        icon: send,
        text: '3. Setting up Multi-touch campaign with proven messages to increase interest.'
    },
    {
        icon: handShake,
        text: '4. You continue the conversation when potential customers respond, so you can build the relationship and close the deal.'
    }
]
const SuccessSteps = () => {
    return (
        <Container>
            <SectionTitle><span>Our 4 steps</span> to your success</SectionTitle>
            <Grid container spacing={3}>
                {
                    steps.map(({ icon, text }, i) => (
                        <Grid item md={3} sm={6} xs={12} key={i}>
                            <Box>
                                <StepCard>
                                    <Box className='icon'>
                                        <img src={icon} alt="" />
                                    </Box>
                                    <Typography className='text' variant='body1'>{text}</Typography>
                                </StepCard>
                            </Box>
                        </Grid>
                    ))
                }
            </Grid>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', my: 8 }}>
                <Link href="#contact" sx={{ textDecoration: "none", mb: 4 }}>
                    <MainButton variant='contained'>Free consultation</MainButton>
                </Link>
                <img src={moneyBack} alt="" />
            </Box>
        </Container>
    );
};

export default SuccessSteps;